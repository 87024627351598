<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Create Attribute Group</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="goto()"
            >
              Back
            </button>
          </div>
        </div>
        <!-- ------------------------ -->

        <div class="row m-0 px-3">
          <div class="col-12 col-xl-7">
            <div class="form-group">
              <label>Cat Code</label>
              <div class="input-group input-group-sm mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="data.cattext"
                  :disabled="mode_edit"
                  @click="callModalcatcode()"
                />
                <div class="input-group-append">
                  <button class="input-group-text px-3" @click="Clearcatcode()">
                    x
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mx-0 mt-3 px-3">
            <p class="font-main font-600">Label Translation</p>
            <hr />
          </div>
          <div class="col-12 col-xl-7">
            <div class="form-group">
              <label for="exampleInputEmail1">Label Thailand</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="data.labelTH"
                :disabled="data.catCode === ''"
              />
            </div>
          </div>
          <div class="col-12 col-xl-7">
            <div class="form-group">
              <label for="exampleInputEmail1">Label English</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="data.labelEN"
                :disabled="data.catCode === ''"
              />
            </div>
          </div>
          <div class="col-12 col-xl-7">
            <b-form-checkbox
              v-model="data.active"
              name="check-button"
              switch
              value="1"
            >
              Active
            </b-form-checkbox>
          </div>
          <div class="col-12 mx-0 mt-4 mb-3 px-3 ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="save()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modalcatcode
      :key="reModalcatcode"
      confirmlvl="3"
      @return_data="return_data"
    />
  </div>
</template>

<script>
import Modalcatcode from "@/components/common/Modalcatcode.vue";
export default {
  name: "CreateAttributesGroup",
  components: { Modalcatcode },
  data() {
    return {
      reModalcatcode: Date.now() + 5,
      mode_edit: false,
      data: {
        attrGroupId: "",
        cattext: "",
        catCode: "",
        labelTH: "",
        labelEN: "",
        active: 1,
      },
    };
  },
  watch: {
    "data.active": function(v) {
      if (!v) {
        this.data.active = 0;
      }
    },
  },
  mounted() {
    this.get_attribute_by_id();
  },
  methods: {
    async get_attribute_by_id() {
      try {
        if (!!this.$route.query?.attrGroupId) {
          let data = {
            catCode: "",
            attrGroupId: this.$route.query?.attrGroupId,
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_attribute_groups`,
            data,
            1
          );

          let {
            attrGroupId,
            active,
            catCode,
            catName,
            labelEN,
            labelTH,
          } = getAPI.data[0];
          this.data.attrGroupId = attrGroupId;
          this.data.cattext = catName;
          this.data.catCode = catCode;
          this.data.labelTH = labelTH;
          this.data.labelEN = labelEN;
          this.data.active = active;
          this.mode_edit = true;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async goto() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.$router.push({ name: "Attribute-group" });
      }
    },
    callModalcatcode() {
      this.reModalcatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalcatcode");
      }, 100);
    },
    return_data(data) {
      let { catcode, selectnow } = data;
      this.data.cattext = selectnow;
      this.data.catCode = catcode;
    },
    Clearcatcode() {
      this.data.cattext = "";
      this.data.catCode = "";
      this.data.labelTH = "";
      this.data.labelEN = "";
    },
    async validate() {
      return new Promise(async (resolve, reject) => {
        let { catCode, labelTH, labelEN } = this.data;
        if (!!!catCode) {
          resolve(`Please select Cat Code / กรุณาเลือก Cat Code`);
        }
        if (!!!labelTH) {
          resolve(`Please enter labelTH / กรุณาระบุ labelTH`);
        }
        if (!!!labelEN) {
          resolve(`Please enter labelEN / กรุณาระบุ labelEN`);
        }
        resolve("OK");
      });
    },
    async save() {
      try {
        let vali = await this.validate();

        if (vali !== "OK") {
          throw vali;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/add_attribute_group`,
            this.data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$router.push({ name: "Attribute-group" });
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
  // async beforeRouteLeave(to, from, next) {
  //   // const answer = window.confirm(
  //   //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
  //   // );
  //   let confirm = await this.$serviceMain.showConfirmAlert(
  //     this,
  //     "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
  //   );
  //   if (confirm) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },
};
</script>

<style></style>
